const initialState = {
    count: 0,
    storeFavIconCount: 0,
    data: {},
    storeTempId: "",
    storeSectionUrl: "",
    storeEntityId: {},
    isJewelDIY: false,
    isDiamoDIY: false,
    storeFilterValueName: "",
    storeSubNavbarName: "",
    isLoginClassAdded: false,
    storeCurrency: "",
    storeHeaderData: {},
    currentStepperTab: "",
    singleDiamondDetail: {},
    itemDynamicParameter: {},
    isEditRingDiamond: "",
};

const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case "countOfCartItems": {
            return {
                ...state,
                count: action.count,
            };
        }

        case "isLoginClassAdded": {
            state.isLoginClassAdded = action.isLoginClassAdded;
            return {
                ...state,
                isLoginClassAdded: state.isLoginClassAdded,
            };
        }

        case "storeSectionUrl": {
            state.storeSectionUrl = action.storeSectionUrl;
            return {
                ...state,
                storeSectionUrl: state.storeSectionUrl,
            };
        }

        case "storeCurrency": {
            state.storeCurrency = action.storeCurrency;
            return {
                ...state,
                storeCurrency: state.storeCurrency,
            };
        }

        case "storeTempId": {
            state.storeTempId = action.storeTempId;
            return {
                ...state,
                storeTempId: state.storeTempId
            }
        }

        case "getLoginDetails": {
            state.data = action.data;
            return {
                ...state,
                data: state.data,
            };
        }

        case "storeSubNavbarName": {
            state.storeSubNavbarName = action.storeSubNavbarName;
            return {
                ...state,
                storeSubNavbarName: state.storeSubNavbarName,
            };
        }

        case "storeHeaderData": {
            state.storeHeaderData = action.storeHeaderData;
            return {
                ...state,
                storeHeaderData: state.storeHeaderData,
            };
        }

        case "isJewelDIY": {
            state.isJewelDIY = action.isJewelDIY;
            return {
                ...state,
                isJewelDIY: state.isJewelDIY,
            };
        }

        case "storeFilterValueName": {
            state.storeFilterValueName = action.storeFilterValueName;
            return {
                ...state,
                storeFilterValueName: state.storeFilterValueName,
            };
        }

        case "isDiamoDIY": {
            state.isDiamoDIY = action.isDiamoDIY;
            return {
                ...state,
                isDiamoDIY: state.isDiamoDIY,
            };
        }

        case "storeFavCount": {
            state.storeFavIconCount = action.storeFavCount;
            return {
                ...state,
                storeFavCount: state.storeFavIconCount,
            };
        }

        case "storeEntityId": {
            state.storeEntityId = action.storeEntityId;
            return {
                ...state,
                storeEntityId: state.storeEntityId,
            };
        }

        case "SET_STEPPER_TAB_NAME": {
            state.currentStepperTab = action.payload;
            return {
                ...state,
                currentStepperTab: action.payload,
            };
        }

        case "ADD_DIAMOND": {
            state.singleDiamondDetail = action.payload;
            return {
                ...state,
                singleDiamondDetail: action.payload,
            };
        }

        case "ADD_DYNAMIC_PARAMS": {
            state.itemDynamicParameter = action.payload;
            return {
                ...state,
                itemDynamicParameter: action.payload,
            };
        }

        case "EDIT_RING_DIAMOND": {
            state.isEditRingDiamond = action.payload;
            return {
                ...state,
                isEditRingDiamond: action.payload,
            };
        }

        default: {
            return {
                ...state,
            };
        }
    }
};

export default Reducer;

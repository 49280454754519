import './Assets/Css/Boostrap/bootstrap.min.css';
import './App.scss';
import React, { useState, useCallback, useEffect } from "react";
import { Routes, Route, useLocation } from 'react-router-dom';
import { RouteComponent, authRoute } from "./Routes/Routes/routes";
import './Icons.scss';
import Header from "../src/Components/HeaderFooter/Header/header";
import Footer from "../src/Components/HeaderFooter/Footer/footer";
import SideBar from './CommanUIComp/Sidebar/sidebar';
import { useDispatch, useSelector } from "react-redux";
import CommanService, { domain } from './CommanService/commanService';
import { storeEntityId, storeHeaderData } from './Redux/action';
import Protected from './Routes/ProtectedRoute/protectedRoutes';
import AuthComponent from './Routes/AuthRoutes/authRoutes';
import ToastComponent from './CommanUIComp/Toast/toast';
import Loader from './CommanUIComp/Loader/loader';
import { diamoSessionData, isEmpty, jewelSessionData, looseSessionData, storeType } from './Components/CommanFunctions/commanFunctions';
import { storeCurrency } from './Redux/action';
import { Suspense } from 'react';
import PageNotFound from './Components/PageNotFound/pageNotFound';
import favLogo from "../src/Assets/Icons/favicon.ico"
import PageUnderConstruction from './Components/PageUnderConstruction/pageUnderConstruction';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { getLoginDetailsData, storeTempId, getCountOfCartItems, storeFavCount } from "./Redux/action";

const App = () => {
  const [customizeshow, customizeshowSetShow] = useState(false);

  const customizerClose = () => customizeshowSetShow(false);
  const customizerShow = () => customizeshowSetShow(true);

  const location = useLocation();
  const hash = location.hash;

  const [activePreset, setActivePreset] = useState('theme-1');
  const [themeIcon, setThemeIcon] = useState('0');
  const [storeTemplateData, setStoreTemplateData] = useState([]);
  const [loader, setLoder] = useState(true);
  const [toastShow, setToastOpen] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [onceUpdated, setOnceUpdated] = useState(false)

  const [fixSideBar, setFixsidebar] = useState(false);
  const [pageUnderConstructionShown, setPageUnderConstructionShown] = useState(false)
  const [isPageView, setIsPageView] = useState(false)

  const selector = useSelector((state) => state);
  const [storeHeaderLogo, setStoreHeaderLogo] = useState([])
  const [headerData, setHeaderData] = useState({})
  let [isStoreApiCalling, setIsStoreApiCalling] = useState(JSON.parse(sessionStorage.getItem("isStoreApiCalling")))

  var store_id = '';
  var email_id = '';
  store_id = isEmpty(hash) != '' && hash.includes('storeId') ? hash.split('storeId=')[1] : '';
  email_id = isEmpty(hash) != '' && hash.includes('email') ? hash.split('email=')[1] : '';

  const handlePresetClick = (preset) => {
    sessionStorage.setItem('presetColor', preset);
    setActivePreset(preset);
  };

  useEffect(() => {
    if (window.location.hash.includes('themeIcon')) {
      setThemeIcon(window.location.hash.split('&themeIcon=')[1]);
    }
    if (sessionStorage.getItem('presetColor')) {
      const preset = sessionStorage.getItem('presetColor');
      setActivePreset(preset);
      document.documentElement.className = preset;
    }
  }, [activePreset]);

  const dispatch = useDispatch()

  const setHeaderLogo = useCallback((data) => {
    const getFavLogo = document.getElementById("favicon")
    if (Object.keys(data).length > 0) {
      const faviconLogo = isEmpty(data.logo_data) !== "" ? data.logo_data.filter((h) => isEmpty(h.logo_type) === "FAVICON") : []
      const filterLogo = isEmpty(data.logo_data) !== "" ? data.logo_data.filter((h) => isEmpty(h.logo_type) === "HEADER") : []
      if (faviconLogo.length > 0) {
        getFavLogo.attributes[2].value = isEmpty(faviconLogo[0].image)
      }
      else {
        getFavLogo.attributes[2].value = favLogo
      }
      setStoreHeaderLogo(filterLogo)
    } else {
      getFavLogo.attributes[2].value = favLogo
      setStoreHeaderLogo([])
    }
  }, [])

  const navigationApiCalling = useCallback((storeEntityId) => {
    var isTrue = false

    const obj = {
      "a": "GetPOSHome",
      "store_id": isEmpty(storeEntityId.mini_program_id),
      type: storeType
    }

    CommanService.postLaravelApi2("NavigationMegamenu", obj).then((res) => {
      if (res.data.success === 1) {
        let data = res.data.data
        isTrue = true
        if (Object.keys(data).length > 0) {
          setHeaderLogo(data)
          setHeaderData(data)
          dispatch(storeHeaderData(data))
          setIsStoreApiCalling(true)
          setLoder(false)
        } else {
          setStoreHeaderLogo([])
          setHeaderData({})
          dispatch(storeHeaderData({}))
          setIsStoreApiCalling(false)
          setLoder(false)
        }
        sessionStorage.setItem("megaMenu", JSON.stringify(data))
      } else {
        setStoreHeaderLogo([])
        setHeaderData({})
        dispatch(storeHeaderData({}))
        setIsStoreApiCalling(false)
        setToastOpen(true)
        setIsSuccess(false)
        setToastMsg(res.data.message)
        setLoder(false)
      }
    }).catch(() => {
      if (sessionStorage.getItem("megaMenu") === null && isTrue === false) {
        setStoreHeaderLogo([])
        setHeaderData({})
        dispatch(storeHeaderData({}))
        setIsStoreApiCalling(false)
      }
      setLoder(false)
    })
  }, [setHeaderLogo, dispatch])

  const colorApi = (publish) => {
    // if (isEmpty(publish["header"] !== "")) {
    //   if (isEmpty(publish["header"]["Top_header"]) !== "") {
    //     if (publish["header"]["Top_header"].Bg_color !== undefined && publish["header"]["Top_header"].Bg_color !== "" && publish["header"]["Top_header"].Bg_color !== null) {
    //       document.documentElement.style.setProperty('--theme-top-header-bg-color', publish["header"]["Top_header"].Bg_color);
    //     } else {
    //       document.documentElement.style.setProperty('--theme-top-header-bg-color', "#ffffff");
    //     }
    //     if (publish["header"]["Top_header"].Text_color !== undefined && publish["header"]["Top_header"].Text_color !== "" && publish["header"]["Top_header"].Text_color !== null) {
    //       document.documentElement.style.setProperty('--theme-top-header-text-color', publish["header"]["Top_header"].Text_color);
    //     } else {
    //       document.documentElement.style.setProperty('--theme-top-header-text-color', "#94a3b8");
    //     }
    //     if (publish["header"]["Top_header"].Text_hover !== undefined && publish["header"]["Top_header"].Text_hover !== "" && publish["header"]["Top_header"].Text_hover !== null) {
    //       document.documentElement.style.setProperty('--theme-top-header-hover-color', publish["header"]["Top_header"].Text_hover);
    //     } else {
    //       document.documentElement.style.setProperty('--theme-top-header-hover-color', "#4f46e5");
    //     }
    //     if (publish["header"]["Top_header"].Bg_hover_color !== undefined && publish["header"]["Top_header"].Bg_hover_color !== "" && publish["header"]["Top_header"].Bg_hover_color !== null) {
    //       document.documentElement.style.setProperty('--theme-top-header-hover-bg', publish["header"]["Top_header"].Bg_hover_color);
    //     } else {
    //       document.documentElement.style.setProperty('--theme-top-header-hover-bg', "#4f46e5");
    //     }
    //   }

    //   if (isEmpty(publish["header"]["second_header"]) !== "") {
    //     if (publish["header"]["second_header"].Text_color !== undefined && publish["header"]["second_header"].Text_color !== "" && publish["header"]["second_header"].Text_color !== null) {
    //       document.documentElement.style.setProperty('--theme-sidebar-text-color', publish["header"]["second_header"].Text_color);
    //     } else {
    //       document.documentElement.style.setProperty('--theme-sidebar-text-color', "#334155");
    //     }
    //     if (publish["header"]["second_header"].Text_hover !== undefined && publish["header"]["second_header"].Text_hover !== "" && publish["header"]["second_header"].Text_hover !== null) {
    //       document.documentElement.style.setProperty('--theme-sidebar-hover-color', publish["header"]["second_header"].Text_hover);
    //     } else {
    //       document.documentElement.style.setProperty('--theme-sidebar-hover-color', "#4f46e5");
    //     }
    //     if (publish["header"]["second_header"].Bg_color !== undefined && publish["header"]["second_header"].Bg_color !== "" && publish["header"]["second_header"].Bg_color !== null) {
    //       document.documentElement.style.setProperty('--theme-sidebar-bg-color', publish["header"]["second_header"].Bg_color);
    //     } else {
    //       document.documentElement.style.setProperty('--theme-sidebar-bg-color', "#ffffff");
    //     }

    //     if (publish["header"]["second_header"].Bg_hover_color !== undefined && publish["header"]["second_header"].Bg_hover_color !== "" && publish["header"]["second_header"].Bg_hover_color !== null) {
    //       document.documentElement.style.setProperty('--theme-sidebar-hover-bg', publish["header"]["second_header"].Bg_hover_color);
    //     } else {
    //       document.documentElement.style.setProperty('--theme-sidebar-hover-bg', "#ffffff");
    //     }
    //   }
    // }

    // if (isEmpty(publish["footer"]) !== "" && isEmpty(publish["footer"]["footer_data"]) !== "") {
    //   if (publish["footer"]["footer_data"].Title_color !== undefined && publish["footer"]["footer_data"].Title_color !== "" && publish["footer"]["footer_data"].Title_color !== null) {
    //     document.documentElement.style.setProperty('--theme-footer-title-color', publish["footer"]["footer_data"].Title_color);
    //   } else {
    //     document.documentElement.style.setProperty('--theme-footer-title-color', "#334155");
    //   }
    //   if (publish["footer"]["footer_data"].Text_color !== undefined && publish["footer"]["footer_data"].Text_color !== "" && publish["footer"]["footer_data"].Text_color !== null) {
    //     document.documentElement.style.setProperty('--theme-footer-text-color', publish["footer"]["footer_data"].Text_color);
    //   } else {
    //     document.documentElement.style.setProperty('--theme-footer-text-color', "#94a3b8");
    //   }
    //   if (publish["footer"]["footer_data"].Text_hover !== undefined && publish["footer"]["footer_data"].Text_hover !== "" && publish["footer"]["footer_data"].Text_hover !== null) {
    //     document.documentElement.style.setProperty('--theme-footer-hover-color', publish["footer"]["footer_data"].Text_hover);
    //   } else {
    //     document.documentElement.style.setProperty('--theme-footer-hover-color', "#4f46e5");
    //   }
    //   if (publish["footer"]["footer_data"].Bg_color !== undefined && publish["footer"]["footer_data"].Bg_color !== "" && publish["footer"]["footer_data"].Bg_color !== null) {
    //     document.documentElement.style.setProperty('--theme-footer-bg-color', publish["footer"]["footer_data"].Bg_color);
    //   } else {
    //     document.documentElement.style.setProperty('--theme-footer-bg-color', "#ffffff");
    //   }
    // }

    // if (isEmpty(publish["bodyContent"]) !== "" && isEmpty(publish["bodyContent"]["body_content"]) !== "") {
    //   if (publish["bodyContent"]["body_content"].Title_color !== undefined && publish["bodyContent"]["body_content"].Title_color !== "" && publish["bodyContent"]["body_content"].Title_color !== null) {
    //     document.documentElement.style.setProperty('--theme-body-title-color', publish["bodyContent"]["body_content"].Title_color);
    //   } else {
    //     document.documentElement.style.setProperty('--theme-body-title-color', "#1b1b1b");
    //   }
    //   if (publish["bodyContent"]["body_content"].Sub_title_color !== undefined && publish["bodyContent"]["body_content"].Sub_title_color !== "" && publish["bodyContent"]["body_content"].Sub_title_color !== null) {
    //     document.documentElement.style.setProperty('--theme-body-sub-title-color', publish["bodyContent"]["body_content"].Sub_title_color);
    //   } else {
    //     document.documentElement.style.setProperty('--theme-body-sub-title-color', "#2b2b2b");
    //   }
    //   if (publish["bodyContent"]["body_content"].Text_color !== undefined && publish["bodyContent"]["body_content"].Text_color !== "" && publish["bodyContent"]["body_content"].Text_color !== null) {
    //     document.documentElement.style.setProperty('--theme-body-text-color', publish["bodyContent"]["body_content"].Text_color);
    //   } else {
    //     document.documentElement.style.setProperty('--theme-body-text-color', "#94a3b8");
    //   }
    //   if (publish["bodyContent"]["body_content"].Text_hover !== undefined && publish["bodyContent"]["body_content"].Text_hover !== "" && publish["bodyContent"]["body_content"].Text_hover !== null) {
    //     document.documentElement.style.setProperty('--theme-body-hover-color', publish["bodyContent"]["body_content"].Text_hover);
    //   } else {
    //     document.documentElement.style.setProperty('--theme-body-hover-color', "#4f46e5");
    //   }
    //   if (publish["bodyContent"]["body_content"].Bg_color !== undefined && publish["bodyContent"]["body_content"].Bg_color !== "" && publish["bodyContent"]["body_content"].Bg_color !== null) {
    //     document.documentElement.style.setProperty('--theme-body-bg-color', publish["bodyContent"]["body_content"].Bg_color);
    //   } else {
    //     document.documentElement.style.setProperty('--theme-body-bg-color', "#f6f8fc");
    //   }
    //   if (publish["bodyContent"]["body_content"].sec_Bg_color !== undefined && publish["bodyContent"]["body_content"].sec_Bg_color !== "" && publish["bodyContent"]["body_content"].sec_Bg_color !== null) {
    //     document.documentElement.style.setProperty('--theme-body-sub-bg-color', publish["bodyContent"]["body_content"].sec_Bg_color);
    //   } else {
    //     document.documentElement.style.setProperty('--theme-body-sub-bg-color', "#ffffff");
    //   }
    //   // Nav Tab color
    //   if (publish["bodyContent"]["body_content"].nav_bg_color !== undefined && publish["bodyContent"]["body_content"].nav_bg_color !== "" && publish["bodyContent"]["body_content"].nav_bg_color !== null) {
    //     document.documentElement.style.setProperty('--theme-body-nav-tab-bg', publish["bodyContent"]["body_content"].nav_bg_color);
    //   } else {
    //     document.documentElement.style.setProperty('--theme-body-nav-tab-bg', "#ffffff");
    //   }

    //   if (publish["bodyContent"]["body_content"].nav_text_color !== undefined && publish["bodyContent"]["body_content"].nav_text_color !== "" && publish["bodyContent"]["body_content"].nav_text_color !== null) {
    //     document.documentElement.style.setProperty('--theme-nav-tab-text-color', publish["bodyContent"]["body_content"].nav_text_color);
    //   } else {
    //     document.documentElement.style.setProperty('--theme-nav-tab-text-color', "#ffffff");
    //   }

    //   // table hover color
    //   if (publish["bodyContent"]["body_content"].table_hover_bg !== undefined && publish["bodyContent"]["body_content"].table_hover_bg !== "" && publish["bodyContent"]["body_content"].table_hover_bg !== null) {
    //     document.documentElement.style.setProperty('--theme-table-hover-bg', publish["bodyContent"]["body_content"].table_hover_bg);
    //   } else {
    //     document.documentElement.style.setProperty('--theme-table-hover-bg', "#ffffff");
    //   }

    //   if (publish["bodyContent"]["body_content"].table_hover_color !== undefined && publish["bodyContent"]["body_content"].table_hover_color !== "" && publish["bodyContent"]["body_content"].table_hover_color !== null) {
    //     document.documentElement.style.setProperty('--theme-table-hover-color', publish["bodyContent"]["body_content"].table_hover_color);
    //   } else {
    //     document.documentElement.style.setProperty('--theme-table-hover-color', "#ffffff");
    //   }
    // }

    // if (isEmpty(publish["button"]) !== "" && isEmpty(publish["button"]["button_data"]) !== "") {
    //   if (publish["button"]["button_data"].button_border_color !== undefined && publish["button"]["button_data"].button_border_color !== "" && publish["button"]["button_data"].button_border_color !== null) {
    //     document.documentElement.style.setProperty('--theme-btn-primary-border-color', publish["button"]["button_data"].button_border_color);
    //   } else {
    //     document.documentElement.style.setProperty('--theme-btn-primary-border-color', "#4338ca");
    //   }
    //   if (publish["button"]["button_data"].button_text_color !== undefined && publish["button"]["button_data"].button_text_color !== "" && publish["button"]["button_data"].button_text_color !== null) {
    //     document.documentElement.style.setProperty('--theme-btn-primary-text-color', publish["button"]["button_data"].button_text_color);
    //   } else {
    //     document.documentElement.style.setProperty('--theme-btn-primary-text-color', "#ffffff");
    //   }
    //   if (publish["button"]["button_data"].button_text_hover !== undefined && publish["button"]["button_data"].button_text_hover !== "" && publish["button"]["button_data"].button_text_hover !== null) {
    //     document.documentElement.style.setProperty('--theme-btn-primary-hover-color', publish["button"]["button_data"].button_text_hover);
    //   } else {
    //     document.documentElement.style.setProperty('--theme-btn-primary-hover-color', "#4338ca");
    //   }
    //   if (publish["button"]["button_data"].button_bg_color !== undefined && publish["button"]["button_data"].button_bg_color !== "" && publish["button"]["button_data"].button_bg_color !== null) {
    //     document.documentElement.style.setProperty('--theme-btn-primary-bg-color', publish["button"]["button_data"].button_bg_color);
    //   } else {
    //     document.documentElement.style.setProperty('--theme-btn-primary-bg-color', "#4f46e5");
    //   }

    //   if (publish["button"]["button_data"].sec_button_border_color !== undefined && publish["button"]["button_data"].sec_button_border_color !== "" && publish["button"]["button_data"].sec_button_border_color !== null) {
    //     document.documentElement.style.setProperty('--theme-btn-secondary-border-color', publish["button"]["button_data"].sec_button_border_color);
    //   } else {
    //     document.documentElement.style.setProperty('--theme-btn-secondary-border-color', "#e9eef5");
    //   }
    //   if (publish["button"]["button_data"].sec_button_text_color !== undefined && publish["button"]["button_data"].sec_button_text_color !== "" && publish["button"]["button_data"].sec_button_text_color !== null) {
    //     document.documentElement.style.setProperty('--theme-btn-secondary-text-color', publish["button"]["button_data"].sec_button_text_color);
    //   } else {
    //     document.documentElement.style.setProperty('--theme-btn-secondary-text-color', "#334155");
    //   }
    //   if (publish["button"]["button_data"].sec_button_text_hover !== undefined && publish["button"]["button_data"].sec_button_text_hover !== "" && publish["button"]["button_data"].sec_button_text_hover !== null) {
    //     document.documentElement.style.setProperty('--theme-btn-secondary-hover-color', publish["button"]["button_data"].sec_button_text_hover);
    //   } else {
    //     document.documentElement.style.setProperty('--theme-btn-secondary-hover-color', "#d5dce5");
    //   }
    //   if (publish["button"]["button_data"].sec_button_bg_color !== undefined && publish["button"]["button_data"].sec_button_bg_color !== "" && publish["button"]["button_data"].sec_button_bg_color !== null) {
    //     document.documentElement.style.setProperty('--theme-btn-secondary-bg-color', publish["button"]["button_data"].sec_button_bg_color);
    //   } else {
    //     document.documentElement.style.setProperty('--theme-btn-secondary-bg-color', "#e9eef5");
    //   }
    // }
  }

  const colorChangesAPI = useCallback((data) => {
    var isTrue = false;
    var preview = '1';
    if ((window.location.href).includes('preview') == true) {
      preview = '1';
    } else {
      preview = '0';
    }
    const obj = {
      a: "PublishTemplate",
      transaction_id: data.transaction_pos_id,
      host: domain,
      SITDeveloper: "1",
      preview: preview,
      secret_key: data.secret_key
    }
    CommanService.postApi("TemplateMaster", obj).then((res) => {
      if (res["data"]["success"] === 1) {
        isTrue = true;
        setStoreTemplateData(res.data.data)
        const ColorData = res["data"]["data"];
        const publish_json = isEmpty(ColorData["publish_json"]) !== "" ? (ColorData["publish_json"]) : "";
        const publish = isEmpty(JSON.parse(publish_json)) !== "" ? JSON.parse(publish_json) : {};
        if (isEmpty(publish['accent_colors']) !== "") {
          sessionStorage.setItem('presetColor', publish['accent_colors']);
          setActivePreset(publish['accent_colors']);
        }
        // if (Object.keys(publish).length > 0) {
        //   sessionStorage.setItem("color_json", isEmpty(publish_json))
        //   colorApi(publish)
        //   sessionStorage.removeItem("imgFilter")
        // }
        setIsPageView(true)
        setPageUnderConstructionShown(false)
        navigationApiCalling(data)
      } else {
        setIsPageView(true)
        setToastOpen(true)
        setIsSuccess(false)
        setPageUnderConstructionShown(true)
        setToastMsg(res.data.message)
        setLoder(false)
      }
    }).catch(() => {
      if (!isTrue) {
        setIsPageView(true)
        setPageUnderConstructionShown(true)
      }
      setLoder(false);
    })
  }, [navigationApiCalling])

  const saveAsDraft = (value1, value2) => {
    const json = {
      accent_colors: activePreset
    }
    var selector = JSON.parse(sessionStorage.getItem('storeData'));
    const obj = {
      a: "saveTemplateCustomizedData",
      SITDeveloper: "1",
      tenant_id: selector.tenant_id,
      entity_id: selector.entity_id,
      member_id: selector.customer_id,
      template_id: isEmpty(storeTemplateData.template_id),
      module: '5',
      publish_status: value1,
      save_draft_status: value2,
      unique_id: isEmpty(storeTemplateData.unique_id),
      preview_url: isEmpty(selector.store_domain),
      publish_url: isEmpty(selector.store_domain),
      lang_id: isEmpty(storeTemplateData.lang_id),
      transaction_id: isEmpty(storeTemplateData.transaction_id),
      json_contents: JSON.stringify(json),
      secret_key: selector.secret_key,
    }
    CommanService.postApi("TemplateMaster", obj).then((res) => {
      if (res.data.success === 1) {
        customizeshowSetShow(false);
      }
    });
  }

  const getStoreData = useCallback(() => {
    setLoder(true)
    const getEntityId = {
      a: "GetStoreData",
      store_domain: domain,
      SITDeveloper: "1",
    };

    CommanService.postApi("EmbeddedPageMaster", getEntityId).then((res) => {
      if (res.data.success === 1) {
        const data = res.data.data;
        sessionStorage.setItem("storeData", JSON.stringify(data))
        if (Object.keys(data).length > 0) {
          if (isEmpty(data.transaction_pos_id) === "") {
            setIsStoreApiCalling(false)
            setIsPageView(true)
            setPageUnderConstructionShown(true)
            setLoder(false)
          } else {
            setPageUnderConstructionShown(false)
            colorChangesAPI(data);
          }
          document.title = res.data.data.store_name
          dispatch(storeCurrency(isEmpty(data.store_currency)))
        } else {
          setIsPageView(true)
          setPageUnderConstructionShown(true)
          setIsStoreApiCalling(false)
          setLoder(false);
        }
        dispatch(storeEntityId(res.data.data));
      } else {
        setIsPageView(true)
        setPageUnderConstructionShown(true)
        setIsStoreApiCalling(false)
        dispatch(storeEntityId({}));
        setToastOpen(true);
        setIsSuccess(false);
        setToastMsg(res.data.message);
        setLoder(false);
      }
    }).catch(() => {
      setIsPageView(true)
      setPageUnderConstructionShown(true)
      setIsStoreApiCalling(false)
      dispatch(storeEntityId({}));
      setLoder(false);
    })
  }, [dispatch, colorChangesAPI])


  const directLogin = () => {
    var storeId = store_id
    if (store_id.includes(';')) {
      storeId = store_id.split(';')[0];
    }
    const obj = {
      a: "StorePOSLogin",
      email: isEmpty(email_id),
      store_id: isEmpty(storeId)
    }
    CommanService.postLaravelApi('AuthController', obj).then((res) => {
      if (res.data.success === 1) {
        dispatch(getLoginDetailsData(res["data"]["data"]));
        dispatch(storeTempId(isEmpty(res["data"]["data"].temp_id)))
        setToastMsg(res.data.message)
        setIsSuccess(true)
        cartCounter(res["data"]["data"], storeId)
      } else {
        setIsSuccess(false)
        setToastMsg(res.data.message)
      }
    }).catch(() => {
    })
  }

  const cartCounter = (login, storeId) => {
    const getCount = {
      "a": "get_count",
      "store_id": isEmpty(storeId),
      "user_id": isEmpty(login.tal_id),
      temp_id: login.temp_id
    }

    CommanService.postLaravelApi("CartMaster", getCount).then((res) => {
      if (res.data.success === 1) {
        const data = res.data.data
        dispatch(getCountOfCartItems(isEmpty(data.cart_count) !== "" ? data.cart_count : 0));
        dispatch(storeFavCount(isEmpty(data.favourite_count) !== "" ? data.favourite_count : 0))
      } else {
        dispatch(getCountOfCartItems(0));
        dispatch(storeFavCount(0))
        setIsSuccess(false)
        setToastMsg(res.data.message)
      }
    }).catch(() => {
      dispatch(getCountOfCartItems(0));
      dispatch(storeFavCount(0))
    })
  }

  useEffect(() => {
    if (store_id && location.hash) {
      directLogin();
    }
  }, [location.hash, store_id]);

  useEffect(() => {
    const getStoreDataItem = JSON.parse(sessionStorage.getItem("storeData"))
    if (!onceUpdated) {
      sessionStorage.setItem("colorCode", "1")
      setOnceUpdated(true)
      jewelSessionData()
      diamoSessionData()
      looseSessionData()
      dispatch(storeEntityId({}))

      if (getStoreDataItem === null) {
        getStoreData()
      }

      if (getStoreDataItem !== null) {
        if (Object.keys(getStoreDataItem).length > 0) {
          setLoder(true)
          dispatch(storeEntityId(getStoreDataItem));
          if (isEmpty(getStoreDataItem.transaction_pos_id) === "") {
            setIsStoreApiCalling(false)
          }
          colorChangesAPI(getStoreDataItem);
          if (sessionStorage.getItem("megaMenu") !== null) {
            setHeaderData(JSON.parse(sessionStorage.getItem("megaMenu")))
          }
          document.title = getStoreDataItem.store_name
          if (selector.storeCurrency === "") {
            dispatch(storeCurrency(isEmpty(getStoreDataItem.store_currency)))
          }
        } else {
          dispatch(storeEntityId({}))
        }
      }
    }

    var count = 0;
    var count2 = 0;
    var count3 = 0;
    var count4 = 0;
    setInterval(() => {
      // Product Height
      var className2 = document.querySelector(".card-img-top");
      if (className2 !== null && className2 !== undefined && className2 !== "") {
        var divElement2 = document.querySelector(".card-img-top");
        if (divElement2.getBoundingClientRect() !== null && divElement2.getBoundingClientRect() !== undefined) {
          var elemRect2 = divElement2.getBoundingClientRect();
          var elemHeight2 = elemRect2.width;
          if (elemHeight2 !== 0) {
            var height2 = document.getElementsByTagName('figure');
            if (height2.length > count2) {
              height2[count2].setAttribute("style", `height:${elemHeight2 + "px"};`);
              count2++;
            } else {
              count2 = 0;
            }
          }
        }
      }

      // Skeleton Height
      var className = document.getElementsByClassName("Skeleton");
      if (className !== null && className !== undefined && className.length > 0) {
        var divElement = document.querySelector(".Skeleton");
        if (divElement.getBoundingClientRect() !== null && divElement.getBoundingClientRect() !== undefined) {
          let elemRect = divElement.getBoundingClientRect();
          let elemHeight = elemRect.width;
          let height = document.getElementsByClassName('.Skeleton');
          if (height.length > count) {
            height[count].setAttribute("style", `height:${elemHeight + "px"};`);
            count++;
          } else {
            count = 0;
          }
        }
      }

      var className1 = document.getElementsByClassName("maxWidth");
      if (className1 !== null && className1 !== undefined && className1.length > 0) {
        let divElement = document.querySelector(".maxWidth");
        if (divElement.getBoundingClientRect() !== null && divElement.getBoundingClientRect() !== undefined) {
          let elemRect = divElement.getBoundingClientRect();
          let elemHeight = elemRect.width;
          let height = document.getElementsByClassName('.images-li');
          let height2 = document.getElementsByClassName('.fluorescence-images');
          if (height.length > count3) {
            height[count3].setAttribute("style", `width:${elemHeight + "px"};`);
            count3++;
          } else {
            count3 = 0;
          }
          if (height2.length > count4) {
            height2[count4].setAttribute("style", `max-height:${elemHeight + "px"};`);
            count4++;
          } else {
            count4 = 0;
          }
        }
      }

      // loader hidden
      var loader = document.getElementById("loader");
      // var body = document.getElementById("body");
      var active = document.getElementsByClassName("navbar-toggler active");
      if (loader !== null || active.length > 0) {
        // body.setAttribute("style", "overflow:hidden;");
        // window.scrollTo(0, 0);
      } else {
        if (active.length === 0) {
          // body.setAttribute("style", "overflow:visible;")
        }
      }

      //const img = document.getElementsByTagName("img");
      // const imageFilter = sessionStorage.getItem("imgFilter");
      // if (img.length > 0) {
      //   // if (imageFilter !== undefined && imageFilter !== null) {
      //   for (let c = 0; c < img.length; c++) {
      //     if (sessionStorage.getItem("colorCode") === "0") {
      //       document.getElementsByTagName("img")[c].style.filter = "grayscale(1)";
      //     } else {
      //       document.getElementsByTagName("img")[c].style.filter = "none";
      //     }
      //   }
      //   // }
      // }

      // document.body.style.overflow = loader ? 'hidden' : 'visible'; // or ''
      // return () => {
      //   // Cleanup: Reset body overflow when the component is unmounted
      //   document.body.style.overflow = 'visible';
      // };
      document.body.style.overflow = loader ? 'hidden' : 'visible';
      document.body.classList.toggle('body-hidden', loader);

      return () => {
        // Cleanup: Reset body overflow and remove the class when the component is unmounted
        document.body.style.overflow = 'visible';
        document.body.classList.remove('body-hidden');
      };
    }, 500);

  }, [dispatch, getStoreData, onceUpdated, selector.storeCurrency, colorChangesAPI, navigationApiCalling])

  const FixsideBarClick = () => {
    setFixsidebar(true);
  }

  const FixsideBarClickClose = () => {
    setFixsidebar(false);
  }

  document.body.addEventListener('click', (event) => {
    if (event.target.id !== "mainProfile" && isEmpty(document.getElementById("profileId")) && isEmpty(document.getElementById("profileId").style.display) === "block") {
      document.getElementById("profileId").style.display = "none"
    }
  }, true);


  return (
    <React.Fragment>
      {/* {loader && <Loader />} */}
      {isPageView ? !pageUnderConstructionShown ? <>
        <section id="mainPartOFpos">
          <div className={`${selector.isLoginClassAdded ? "HomepageConatin ml-75" : fixSideBar === false ? "ml-75" : "ml-0"}`} >
            {selector.isLoginClassAdded && <Header fixsidebarclick={() => FixsideBarClick()} fixsidebar={fixSideBar} fixsidebarclickclose={() => FixsideBarClickClose()} colorApi={(e) => {
              colorApi(e)
            }} storeHeaderLogo={storeHeaderLogo} />}
            {selector.isLoginClassAdded && <SideBar data={headerData} fixsidebarclick={() => FixsideBarClick()} fixsidebar={fixSideBar} fixsidebarclickclose={() => FixsideBarClickClose()} />}
            <div className={`${selector.isLoginClassAdded ? "dashboeader" : ""}`}>
              <Suspense fallback={<Loader />}>
                <Routes>
                  {authRoute.map((route, index) => {
                    return <Route
                      key={index}
                      path={route.path}
                      component={route.component}
                      element={
                        <Suspense fallback={<Loader />}>
                          <AuthComponent>
                            {route.element}
                          </AuthComponent>
                        </Suspense>
                      }
                    />
                  })}
                  {RouteComponent.map((route, index) => {
                    return <Route
                      exact
                      key={index}
                      path={route.path}
                      component={route.component}
                      element={
                        <Suspense fallback={<Loader />}>
                          <Protected >
                            {route.element}
                          </Protected>
                        </Suspense>
                      }
                    >
                    </Route>
                  })}
                  <Route path="*" element={
                    <Suspense fallback={<Loader />}>
                      <PageNotFound />
                    </Suspense>}></Route>
                </Routes>
              </Suspense>
            </div>
            {selector.isLoginClassAdded && <Footer data={headerData} isStoreApiCalling={isStoreApiCalling} />}
          </div>
        </section>
      </>
        :
        <PageUnderConstruction />
        : <></>
      }
      {themeIcon == '1' ? <>
        <div className="customizerBtn" onClick={customizerShow}>
          <i className="ic_setting"></i>
        </div>
      </> : ''}
      <Offcanvas className="customizer-modal" show={customizeshow} onHide={customizerClose} placement={'end'}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h5 className="mb-0">Theme Customize</h5>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="theme-title">Theme Colors</div>
          <div className="theme-color">
            <a className={activePreset === 'theme-1' ? 'active' : ''} data-value="theme-1" onClick={() => handlePresetClick('theme-1')}>
              <div className="circle-box">
                <div className="colors-box">
                  <span className="theme-circle"></span>
                  <span className="theme-circle"></span>
                  <span className="theme-circle"></span>
                </div>
              </div>
            </a>
            <a className={activePreset === 'theme-2' ? 'active' : ''} data-value="theme-2" onClick={() => handlePresetClick('theme-2')}>
              <div className="circle-box">
                <div className="colors-box">
                  <span className="theme-circle"></span>
                  <span className="theme-circle"></span>
                  <span className="theme-circle"></span>
                </div>
              </div>
            </a>
            <a className={activePreset === 'theme-3' ? 'active' : ''} data-value="theme-3" onClick={() => handlePresetClick('theme-3')}>
              <div className="circle-box">
                <div className="colors-box">
                  <span className="theme-circle"></span>
                  <span className="theme-circle"></span>
                  <span className="theme-circle"></span>
                </div>
              </div>
            </a>
            <a className={activePreset === 'theme-4' ? 'active' : ''} data-value="theme-4" onClick={() => handlePresetClick('theme-4')}>
              <div className="circle-box">
                <div className="colors-box">
                  <span className="theme-circle"></span>
                  <span className="theme-circle"></span>
                  <span className="theme-circle"></span>
                </div>
              </div>
            </a>
            <a className={activePreset === 'theme-5' ? 'active' : ''} data-value="theme-5" onClick={() => handlePresetClick('theme-5')}>
              <div className="circle-box">
                <div className="colors-box">
                  <span className="theme-circle"></span>
                  <span className="theme-circle"></span>
                  <span className="theme-circle"></span>
                </div>
              </div>
            </a>
            <a className={activePreset === 'theme-6' ? 'active' : ''} data-value="theme-6" onClick={() => handlePresetClick('theme-6')}>
              <div className="circle-box">
                <div className="colors-box">
                  <span className="theme-circle"></span>
                  <span className="theme-circle"></span>
                  <span className="theme-circle"></span>
                </div>
              </div>
            </a>
            <a className={activePreset === 'theme-7' ? 'active' : ''} data-value="theme-7" onClick={() => handlePresetClick('theme-7')}>
              <div className="circle-box">
                <div className="colors-box">
                  <span className="theme-circle"></span>
                  <span className="theme-circle"></span>
                  <span className="theme-circle"></span>
                </div>
              </div>
            </a>

          </div>
        </Offcanvas.Body>
        <div className="Offcanvas-footer">
          <button className="btn info-button" onClick={() => saveAsDraft('1', '0')}>Publish</button>
        </div>
      </Offcanvas>
      <ToastComponent
        show={toastShow}
        success={isSuccess}
        onClose={() => setToastOpen(false)}
        header={isSuccess ? "Success" : "Error"}
        toastBody={<div>{toastMsg}</div>}
      />
    </React.Fragment>
  );
}

export default App;